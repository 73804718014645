import React from "react";

const Slogam = (props) => {
	return (
		<div className="slogan">
			<div>
				Let's <span className="grow-text">GROW</span> with us.
			</div>
		</div>
	);
};

export default Slogam;
