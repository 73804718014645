import React from "react";

const CustomeMenuBtn = ({ state, onClickHandler }) => {
	return (
		<div className="navbar">
			<div className="nav-wrapper" onClick={onClickHandler}>
				<span className={!state.clicked ? "nav-line display nav-line1" : "nav-line nav-line1"}></span>
				<span className={!state.clicked ? "nav-line display nav-line2" : "nav-line nav-line2"}></span>
				<span className={!state.clicked ? "nav-line display nav-line3" : "nav-line nav-line3"}></span>
				<span className={state.clicked ? "nav-line-close display nav-line4" : "nav-line-close nav-line4"}></span>
				<span className={state.clicked ? "nav-line-close display nav-line5" : "nav-line-close nav-line5"}></span>
			</div>
		</div>
	);
};

export default CustomeMenuBtn;
