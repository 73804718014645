import gsap from "gsap/gsap-core";
import React, { useEffect, useState } from "react";
import CustomeMenuBtn from "./CustomeMenuBtn";
import Menu from "./Menu";
const Background = (props) => {
	const [state, setState] = useState({
		open: false,
		initial: false,
		clicked: null,
	});

	const [disabled, setDisabled] = useState(false);

	const onClickHandler = () => {
		disableMenu();
		if (state.initial === false) {
			setState({
				initial: null,
				clicked: true,
				menuName: "Close",
			});
		} else if (state.clicked === true) {
			setState({
				clicked: !state.clicked,
				menuName: "Menu",
			});
		} else if (state.clicked === false) {
			setState({
				clicked: !state.clicked,
				menuName: "Close",
			});
		}
	};

	//Determine if out menu button should be disabled
	const disableMenu = () => {
		setDisabled(!disabled);
		setTimeout(() => {
			setDisabled(false);
		}, 1200);
		return disabled;
	};

	useEffect(() => {
		gsap.from(".right-section-div", 2, { scale: 1.2, overflow: "hidden" });
		gsap.fromTo(".slogan", { duration: 0.6, opacity: 0, y: 100 }, { opacity: 1, y: 0 });
	}, []);

	return (
		<>
			<CustomeMenuBtn
				onClickHandler={() => {
					if (!disabled) {
						onClickHandler();
					}
				}}
				state={state}
			/>
			<div className="left-section">
				<Menu state={state} setState={setState} />
			</div>
			<div className="right-section">
				<div className="right-section-div"></div>
			</div>
		</>
	);
};

export default Background;
