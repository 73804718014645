import "./style/style.scss";
import React from "react";
import { Route } from "react-router-dom";
import LandingPage from "LandingPage";

function App(props) {
	return (
		<div className="App">
			<Route path="/" exact render={() => <LandingPage />} />
		</div>
	);
}

export default App;
