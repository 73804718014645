import React, { useEffect } from "react";
import { gsap } from "gsap";
import { CSSRulePlugin } from "gsap/all";
const SocialMedias = (props) => {
	useEffect(() => {
		gsap.fromTo(".fb-link", 0.6, { x: -100, opacity: 0 }, { x: 0, opacity: 1, delay: 0.3 });
		gsap.fromTo(".instagram-link", 0.6, { x: -100, opacity: 0 }, { x: 0, opacity: 1, delay: 1 });
		let socialM = CSSRulePlugin.getRule(".social-media::before");
		gsap.fromTo(socialM, { duration: 2, width: 0 }, { width: "200px" });
	}, []);

	return (
		<div className="social-media">
			<div>
				<a className="fb-link" rel="noreferrer" href="https://www.facebook.com/manansoni91" target="_blank">
					Facebook
				</a>
			</div>
			<div>
				<a className="instagram-link" rel="noreferrer" href="https://www.instagram.com/mxnxnn_/" target="_blank">
					Instagram
				</a>
			</div>
		</div>
	);
};

export default SocialMedias;
