import gsap from "gsap";
import React, { useEffect } from "react";

const Heading = (props) => {
	useEffect(() => {
		gsap.from([".green-text", ".outline-text-span"], 0.8, { y: -800, stagger: { amount: 0.1 } });
	}, []);
	return (
		<div className="headings">
			{/* <div className="line-above">
				<span></span>
			</div> */}
			<div className="green greentxt">
				<span className="green-text">GREEN</span>
			</div>
			<div className="outline-text">
				<span className="outline-text-span">ADVERTISERS</span>
			</div>
		</div>
	);
};

export default Heading;
