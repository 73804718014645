import Background from "Components/Background";
import Heading from "Components/Heading";
import Slogan from "Components/Slogan";
import SocialMedias from "Components/SocialMedias";
import React from "react";

const LandingPage = (props) => {
	return (
		<div className="container ">
			<Background />

			<Heading />
			<SocialMedias />
			<Slogan />
		</div>
	);
};

export default LandingPage;
