import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";

const Menu = ({ state, setState }) => {
	let reveal1 = useRef(null);
	let reveal2 = useRef(null);
	let reveal3 = useRef(null);
	let submenu = useRef(null);

	const [subMenu, setSubMenu] = useState({
		clicked: null,
		initial: false,
	});

	const [disabled, setDisabled] = useState(false);

	const disableMenu = () => {
		setDisabled(!disabled);
		setTimeout(() => {
			setDisabled(false);
		}, 1000);
		return disabled;
	};

	useEffect(() => {
		if (state.clicked === true) {
			gsap.to(reveal1, { duration: 0, css: { display: "block" } });
			gsap.to(reveal2, {
				duration: 0.8,
				opacity: 1,
				width: "80vw",
			});
			gsap.to(reveal3, {
				duration: 0.8,
				opacity: 1,
				width: "70vw",
			});
			gsap.from([".link1-text", ".link2-text", ".link3-text", ".link4-text"], 0.8, {
				y: 100,
				delay: 0.6,
				stagger: { amount: 0.6 },
			});
		} else if (state.clicked === false) {
			gsap.to([reveal2, reveal3], {
				duration: 0.8,
				width: 0,
				ease: "power3.inOut",
				stagger: {
					amount: 0.07,
				},
			});
			gsap.to(reveal1, { duration: 1, css: { display: "none" } });
		}
	}, [state]);

	useEffect(() => {
		if (subMenu.clicked) {
			gsap.to(submenu, { duration: 0, css: { display: "flex" } });
			gsap.to(submenu, { duration: 0.3, height: 150 });

			gsap.fromTo(
				[".sm-link1", ".sm-link2", ".sm-link3", ".sm-link4"],
				{
					y: 100,
					skewX: 4,
					duration: 0.3,
					stagger: { amount: 0.6 },
				},
				{
					y: 0,
					skewX: 0,
				}
			);
		} else {
			gsap.to(submenu, { duration: 0.3, height: 0 });
			gsap.to(submenu, { duration: 0.6, css: { display: "none" } });
		}
	}, [subMenu]);

	return (
		<div ref={(el) => (reveal1 = el)} className={"grouped"}>
			<div ref={(el) => (reveal2 = el)} className="menu-green"></div>
			<div ref={(el) => (reveal3 = el)} className="menu-shadow">
				<div className="menu">
					<div className="menu-wrapper">
						<div className="link link1">
							<div
								className={subMenu.clicked ? "link1-text green" : "link1-text"}
								onClick={() => {
									if (!disabled) {
										disableMenu();
										setSubMenu((prev) => ({ ...subMenu, clicked: !prev.clicked }));
									}
								}}
							>
								LOGIN
							</div>
						</div>
						<div ref={(el) => (submenu = el)} className="sub-menu">
							<div>
								<a href="/erp/" className="sm-link1">
									CLIENT
								</a>
							</div>
							<div>
								<a href="/erp/vendor" className="sm-link2">
									VENDOR
								</a>
							</div>
							<div>
								<a href="/erp/agent" className="sm-link3">
									AGENT
								</a>
							</div>
							<div>
								<a href="/erp/admin" className="sm-link4">
									ADMIN
								</a>
							</div>
						</div>

						<div className="link link2">
							<a href="/" className="link2-text">
								QUOTATION
							</a>
						</div>
						<div className="link link3">
							<a href="/" className="link3-text">
								SERVICES
							</a>
						</div>
						<div className="link link3">
							<a href="/" className="link4-text">
								CONTACT US
							</a>
						</div>
						<div className="link developer">
							<a href="/" className="link5-text">
								Mxnxn Dev.
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Menu;
